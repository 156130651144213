import React, { useState, useCallback, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout-2";
import SEO from "../components/seo";
import useHorizontal from "@oberon-amsterdam/horizontal/hook";
import useWindowSize from "../hooks/useWindowSize";
import MenuDrawer from "../components/atoms/menuDrawer";
import {
  Flex,
  Box,
  Image,
} from "@chakra-ui/react";

// useRef in useEffect issue solution --> https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
function useHookWithRefCallback() {
  const ref = useRef(null)
  const setRef = useCallback(node => {
    if (ref.current) {
      // Make sure to cleanup any events/references added to the last instance
    }
    
    if (node) {
      // Check if a node is actually passed. Otherwise node would be null.
      // You can now do what you need to, addEventListeners, measure, etc.
    }
    
    // Save a reference to the node
    ref.current = node
  }, [])
  
  return [setRef]
}


function Ingatlanok() {
  const data = useStaticQuery(graphql`
    query IngalanImages {
      allCloudinaryMedia(filter: {tags: {eq: "ingatlan"}}, sort: {fields: created_at}) {
        edges {
          node {
            secure_url
            tags
          }
        }
      }
    }
  `);
  
  // In your component you'll still recieve a `ref`, but it 
  // will be a callback function instead of a Ref Object
  const [ref] = useHookWithRefCallback()

  const clImages = data.allCloudinaryMedia.edges;

  if (typeof window != "undefined") {
    if (window > breakpoint) {
      document.documentElement.style.overscrollBehavior = "none";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overscrollBehavior = "auto";
      document.documentElement.style.overflow = "auto";
    }
  }

  // Store which element the useHorizontal hook will be reffered to.
  const [scrollContainer, setContainer] = useState();

  // Capture and store screen size
  const { width } = useWindowSize();
  const breakpoint = 1024;

  // console.log(width);

  useHorizontal({ container: scrollContainer });

  return (
    <Layout>
      <SEO
        keywords={[
          `épület`,
          `enteriőr`,
          `ingatlan`,
          `iroda`,
          `lakás`,
          `építészetií`,
          `fotó`,
          `fotós`,
          `fotográfus`,
        ]}
        title="Ingatlanok | GÁBOR TAMÁS"
      />
        <Flex
          ref={() => {
            width > breakpoint ? setContainer(ref) : null;
          }}
          flexDirection={["column", "column", "row"]}
          p={0}
          m={0}
          mt={[2, 2, 0]}
          sx={{ height: ["100%", "100%", "calc(100vh - 192px)"] }}
        >
          {clImages.map((image, index) => {
            return (
              <Box
                mr={[0, 0, "5vh"]}
                minWidth="fit-content"
                _notLast={{mb: [6, 6, 0]}}
                _first={{ pl: [0, 0, 12] }}
                _last={{ pr: [0, 0, 12] }}
                key={`${index}-cl`}>
                <Image
                  loading="lazy"
                  sx={{
                    height: ["auto", "auto", "calc(100vh - 192px)"],
                  }}
                  key={image.id}
                  objectFit="cover"
                  src={image.node.secure_url}
                  alt={image.node.tags}
                />
              </Box>
            );
          })}
        </Flex>
    <MenuDrawer/>
    </Layout>
  );
}

export default Ingatlanok;
